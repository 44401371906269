import React from "react"
import {Link} from "gatsby"
import kebabCase from 'lodash/kebabCase';
import Layout from "../components/layout"
import SEO from "../components/seo";
import Social from '../components/social';

export default function StateTaxForm({pageContext}) {
  const { forms, stateName } = pageContext;

  const currentDate = new Date().getFullYear();

  return (
    <Layout>
      <SEO title={stateName + " Tax Forms " + currentDate } 
      description={"Download " + currentDate +  " tax forms for " + stateName + ", including: " 
      + forms[0]["form_name"] + ", " + forms[1]["form_name"] + ", " + forms[2]["form_name"] + ", " + 
      forms[3]["form_name"] + ", "  + forms[4]["form_name"] + ", "  + forms[5]["form_name"] + "..."   }/>
      <div>

        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <nav aria-label="breadcrumb">
                <div className="breadcrumb">
                  <div className="breadcrumb-item"><Link to="/">Home</Link></div>
                  <div className="breadcrumb-item"><Link to="/tax-forms">Tax Forms</Link></div>
                  <div className="breadcrumb-item active" aria-current="page">{stateName}</div>
                </div>
              </nav>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <p className="hero-meta">Last updated: {currentDate}</p>
              <h1 className="hero-heading">{stateName} Tax Forms</h1>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-sm-12 col-md-12">
              <Social />
              <ul>
                {forms.map(form => {
                  const formName = form.form_name.replace(/\s/g, '-').toLowerCase();
                  const slug = "/tax-forms/" + kebabCase(stateName) + "/" + formName;

                  return(
                    <li>
                      <Link to={slug}>{form.form_name}</Link>
                    </li>
                  )
                })}
              </ul>
            </div>
            {/* End main column */}
          </div>
          {/* End row */}
        </div>
        {/* End container */}
      </div>
    </Layout>
  );
}

